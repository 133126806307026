import imgBiscato from './../asset/img11.png';
import moveme_img1 from './../asset/moveme/movemegit.png'; 


import biscato_img1 from './../asset/biscato.png';

import rollebal from './../asset/Roleball.png';

import redemin from './../asset/redemin.png'; 

import portufolio from './../asset/portufolio.png'; 


import jasei_img1 from './../asset/jasei/jasei.png'; 

import kangendemdacity from './../asset/kangendemdacity.png'; 

import theds from './../asset/theds.png'; 

import moveme_img2 from './../asset/moveme/moveme_img2.png'; 
import moveme_img3 from './../asset/moveme/Screenshot 2025-03-31 113141.png'; 
import moveme_img4 from './../asset/moveme/Screenshot 2025-03-31 113147.png'; 
import moveme_img5 from './../asset/moveme/Screenshot 2025-03-31 113152.png'; 
import moveme_img6 from './../asset/moveme/Screenshot 2025-03-31 113159.png'; 
import moveme_img7 from './../asset/moveme/Screenshot 2025-03-31 113207.png'; 
import moveme_img8 from './../asset/moveme/Screenshot 2025-03-31 113227.png'; 
import moveme_img9 from './../asset/moveme/Screenshot 2025-03-31 113240.png'; 

export const projects = [
  {
    id: 1,
    title: "Biscato",
    descricao: "O Biscato é uma plataforma inovadora que conecta clientes e prestadores de serviços de diversas áreas. A proposta do projeto é facilitar a busca e contratação de profissionais, garantindo uma experiência segura, rápida e eficiente tanto para quem oferece quanto para quem procura serviços.",
    img: biscato_img1, // Substituir pela importação correta da imagem
    imgCapa: biscato_img1,
    linkProjeto: "https://biscato.com",
    linkGithub: "https://github.com/seuuser/biscato",
    tecnologias: [
      { name: "React", className: "react-icon" },
      { name: "TypeScript", className: "typescript-icon" },
      { name: "Sass", className: "sass-icon" },
      { name: "CSS", className: "css-icon" },
      { name: "Bootstrap", className: "bootstrap-icon" },
      { name: "Firebase", className: "firebase-icon" },
      { name: "Figma", className: "figma-icon" },
      { name: "Photoshop", className: "photoshop-icon" }
    ],
    technologiesUsed: [
      { name: "React" },
      { name: "TypeScript" },
      { name: "Sass" },
      { name: "CSS" },
      { name: "Bootstrap" },
      { name: "Firebase" },
      { name: "Figma" },
      { name: "Photoshop" }
    ],
    difficultiesFaced: "O maior desafio do Biscato foi a implementação do sistema de pagamento e a estruturação da relação entre clientes e profissionais. Precisava garantir que, ao contratar um serviço, houvesse um fluxo claro de solicitação, aceitação e acompanhamento do trabalho. Além disso, a organização do histórico de trabalhos contratados e aceitos foi um ponto crítico, pois os usuários precisavam visualizar de forma clara quais serviços contrataram e quais foram aceitos por eles. Outra grande dificuldade foi a parte de UI/UX: inicialmente, comecei a criar as telas sem um protótipo adequado, o que gerou muitas mudanças e inconsistências visuais. Tive que aprender a utilizar o Figma para padronizar a interface e tornar o processo mais eficiente. Durante o desenvolvimento, também utilizei o ChatGPT para acelerar a escrita do código, mas, sem um planejamento adequado, acabei gerando um código desorganizado e repleto de erros, o que me obrigou a refatorar boa parte do projeto para torná-lo mais legível e estruturado.",
    solutionsAndLearnings: "Para solucionar os desafios enfrentados, implementei o Firebase Authentication para o gerenciamento de usuários e desenvolvi um sistema robusto de organização de serviços utilizando banco de dados em tempo real. O aprendizado no uso do Figma foi essencial para garantir um design mais consistente e profissional. Além disso, passei a adotar melhores práticas de programação para evitar a geração de código confuso e difícil de manter. A experiência também me ensinou a importância da refatoração e da documentação do código, garantindo que o desenvolvimento fosse sustentável e escalável.",
    conclusion: "O Biscato se tornou uma plataforma confiável e eficiente para conectar clientes e profissionais. Através da implementação de novas funcionalidades e melhorias contínuas, a experiência do usuário foi otimizada, tornando o serviço mais acessível e intuitivo. O projeto ainda está em evolução, mas já se mostra uma solução promissora para facilitar contratações de serviços diversos.",
    imgsPhocts: [
      imgBiscato,
      imgBiscato,
      imgBiscato,
      imgBiscato
    ],
    tags: ["Web"]
  },
  {
    id: 3,
    title: "Kandengue Da City",
    descricao: "Kandengue Da City é um jogo emocionante de corrida infinita onde os jogadores controlam Mayu, um jovem aventureiro que deve superar diversos obstáculos e desafios em uma cidade vibrante e perigosa. Com uma jogabilidade dinâmica e controles intuitivos, o jogo proporciona uma experiência divertida e viciante para os jogadores.",
    img: kangendemdacity, // Substituir pela importação correta da imagem
    imgCapa: "kandengue-cover.jpg",
    linkProjeto: "https://play.google.com/store/apps/details?id=com.kandenguedacity.game",
    linkGithub: "https://github.com/seu-usuario/KandengueDaCity",
    tecnologias: [
      { name: "Unity", className: "unity-icon" },
      { name: "C#", className: "csharp-icon" },
      { name: "Firebase", className: "firebase-icon" }
    ],
    technologiesUsed: [
      { name: "Unity" },
      { name: "C#" },
      { name: "Firebase" }
    ],
    difficultiesFaced: "Uma das maiores dificuldades enfrentadas no desenvolvimento de Kandengue Da City foi a implementação do Firebase para coletar estatísticas sobre os jogadores. Precisávamos entender quantas pessoas estavam baixando o jogo e quanto tempo elas passavam jogando, mas configurar corretamente essa integração exigiu várias tentativas e testes. Além disso, a movimentação do personagem também foi um grande desafio. Enfrentamos problemas de sincronização das animações com os controles, o que fazia com que os movimentos de Mayu parecessem desajustados ou bugados. A lógica de colisão também apresentou dificuldades, pois algumas colisões não estavam sendo detectadas corretamente, causando falhas na jogabilidade.",
    solutionsAndLearnings: "Para solucionar os desafios técnicos, estudamos a documentação do Firebase e realizamos testes práticos até conseguir capturar os dados dos jogadores corretamente. Implementamos eventos personalizados para rastrear interações específicas, como a duração da sessão e a frequência de jogo. Em relação à movimentação do personagem, ajustamos os tempos das animações e refinamos a lógica de controle, garantindo que os saltos, deslizamentos e desvios ocorressem de forma fluida. Além disso, aplicamos técnicas de depuração para identificar e corrigir as falhas na colisão dos objetos. Esses desafios nos ensinaram a importância de testes rigorosos e ajustes finos na mecânica do jogo.",
    conclusion: "Kandengue Da City se tornou um jogo sólido e divertido, disponível na Google Play Store para Android. Com uma jogabilidade fluida e mecânicas bem ajustadas, os jogadores podem desfrutar de desafios progressivos e uma experiência imersiva. O projeto ainda tem espaço para melhorias e novas funcionalidades, mas os problemas iniciais foram superados com sucesso, garantindo uma experiência agradável para os jogadores.",
    imgsPhocts: [
      imgBiscato,
      imgBiscato,
      imgBiscato,
      imgBiscato
    ],
    tags: ["Game"]
  },
  {
    id: 4,
    title: "JaSei!",
    descricao: "JaSei! é um jogo de perguntas divertido e educativo, criado para crianças que querem aprender brincando! Desafie seus conhecimentos e ganhe pontos respondendo a perguntas sobre diversos temas. O jogo oferece níveis desbloqueáveis, um sistema de tempo e uma jogabilidade envolvente.",
    img: jasei_img1,
    imgCapa: "jasei-cover.jpg",
    linkProjeto: "https://play.google.com/store/apps/details?id=com.moochastudio.jasei",
    linkGithub: "https://github.com/seu-usuario/JaSei",
    tecnologias: [
      { name: "Unity", className: "unity-icon" },
      { name: "C#", className: "csharp-icon" },
      { name: "TextMeshPro", className: "textmeshpro-icon" },
      { name: "PlayerPrefs", className: "playerprefs-icon" },
      { name: "Firebase", className: "firebase-icon" },
      { name: "Google AdMob", className: "admob-icon" }
    ],
    technologiesUsed: [
      { name: "Unity" },
      { name: "C#" },
      { name: "TextMeshPro" },
      { name: "PlayerPrefs" },
      { name: "Firebase" },
      { name: "Google AdMob" }
    ],
    difficultiesFaced: "O desenvolvimento do JaSei! apresentou vários desafios significativos. Primeiramente, a criação e seleção das perguntas foi mais trabalhosa do que imaginava - precisei escrever e organizar centenas de questões educativas para diferentes níveis. Outro grande obstáculo foi a implementação do Firebase para analytics (para rastrear de onde vinham os usuários, quantas vezes jogavam, etc.) e do Google AdMob para monetização com anúncios. O maior problema técnico foi a incompatibilidade entre o SDK do Firebase e a versão do Unity que estava utilizando, o que causava constantes conflitos e erros. Tentei de tudo: assisti inúmeros tutoriais no YouTube, li a documentação oficial, consultei o ChatGPT, mas nada resolvia - até que encontrei a solução em um comentário quase perdido no GitHub que finalmente resolveu o problema.",
    solutionsAndLearnings: "Para superar esses desafios, organizei as perguntas em um sistema categorizado por dificuldade e tema. Quanto aos problemas técnicos, após muita pesquisa e tentativas frustradas, a solução veio ao descobrir a versão correta do SDK Firebase compatível com meu projeto Unity através daquele comentário no GitHub. Implementei então tanto o Firebase Analytics para entender o comportamento dos usuários quanto o AdMob para exibir anúncios de forma não intrusiva. Aprendi que às vezes as soluções estão nos lugares mais inesperados e que persistência é essencial no desenvolvimento de jogos.",
    conclusion: "JaSei! se tornou não apenas um jogo educativo completo, mas também uma valiosa experiência de aprendizado. Superar os desafios técnicos, especialmente com Firebase e AdMob, resultou em um produto mais robusto e com capacidade de crescimento. O jogo hoje proporciona diversão educativa para crianças enquanto gera dados valiosos e receita através de anúncios, mostrando que os obstáculos do desenvolvimento foram transformados em oportunidades de melhoria.",
    imgsPhocts: [
      imgBiscato,
      imgBiscato,
      imgBiscato,
      imgBiscato
    ],
    tags: ["Game"]
},
{
  id: 5,
  title: "Movime",
  descricao: "Movime é uma plataforma inovadora que conecta empresas e profissionais de transporte, facilitando o gerenciamento e a coordenação de serviços de transporte de forma eficiente. A plataforma oferece uma solução prática para otimizar o planejamento, a execução e o acompanhamento de movimentações de carga e logística.. Com um dashboard administrativo poderoso, oferece ferramentas avançadas para gerenciamento de frotas, monitoramento em tempo real e análise financeira detalhada para empresas de transporte.",
  img: moveme_img1,
  imgCapa: moveme_img2,
  linkProjeto: "https://movime-taxi.web.app",
  linkGithub: "https://github.com/usuario/movime-taxi",
  tecnologias: [
      {name: "React", "className": "react-icon"},
      {name: "TypeScript", "className": "typescript-icon"},
      {name: "Firebase", "className": "firebase-icon"},
      {name: "MapBox", "className": "mapbox-icon"},
      {name: "Material UI", "className": "materialui-icon"},
      {name: "Chart.js", "className": "chartjs-icon"}
  ],
  technologiesUsed: [
      {name: "React"},
      {name: "TypeScript"},
      {name: "Firebase"},
      {name: "MapBox"},
      {name: "Google Maps API"},
      {name: "Firebase Functions"},
      {name: "Admin SDK"}
  ],
  difficultiesFaced: "Implementação de zonas de serviço com polígonos dinâmicos teve problemas de performance. O módulo financeiro apresentou desafios no cálculo automático de comissões. Processar grandes volumes de dados de corridas em tempo real exigiu otimizações constantes.",
  solutionsAndLearnings: "Solução híbrida com MapBox e WebSockets para geofences. Sistema financeiro com Firebase Functions registrando transações imutáveis. Dashboard otimizado com virtualização de dados. Aprendizado valioso em renderização de mapas, contabilidade digital e processamento de dados em tempo real.",
  conclusion: "O Movime Taxi tornou-se uma solução profissional para gestão de frotas, superando desafios técnicos complexos. O dashboard oferece visão completa das operações e finanças, provando ser uma ferramenta valiosa para empresas de transporte.",
  imgsPhocts: [
    moveme_img3,
    moveme_img4,
    moveme_img5,
    moveme_img6,
    moveme_img7,
    moveme_img8,
    moveme_img9
  ],
  tags: ["Web"]
},
{
  id: 6,
  title: "Threads",
  descricao: "Plataforma de rede social inspirada no Threads do Instagram, desenvolvida para criar conteúdo educacional no YouTube. Permite conexões entre usuários, compartilhamento de publicações e interações através de curtidas e comentários, com interface simplificada e focada em conversas significativas.",
  img: theds,
  imgCapa: theds,
  linkProjeto: "https://threads-demo.web.app",
  linkGithub: "https://github.com/your-username/threads",
  tecnologias: [
    {name: "React", className: "react-icon"},
    {name: "TypeScript", className: "typescript-icon"},
    {name: "Firebase", className: "firebase-icon"},
    {name: "React Bootstrap", className: "bootstrap-icon"},
    {name: "Vite", className: "vite-icon"}
  ],
  technologiesUsed: [
    {name: "React"},
    {name: "TypeScript"},
    {name: "Firebase Auth"},
    {name: "Firestore"},
    {name: "React Router"}
  ],
  difficultiesFaced: "Desafios incluiram a implementação da autenticação segura com Firebase, sincronização em tempo real das interações sociais (curtidas/comentários), e criação de um feed dinâmico que mistura conteúdo de seguidos e sugestões.",
  solutionsAndLearnings: "Utilizei React Hooks para gerenciamento de estado, Firebase Security Rules para proteção de dados, e otimização de queries no Firestore. Aprendi técnicas avançadas de lazy loading e virtualização de listas para melhor performance do feed.",
  conclusion: "O projeto Threads serviu como excelente material educativo para meu canal no YouTube, demonstrando na prática como construir uma rede social funcional. Apesar de inspirado no Threads do Instagram, a implementação trouxe desafios únicos que enriqueceram meu conhecimento em desenvolvimento full-stack.",
  imgsPhocts: [
    imgBiscato,
    imgBiscato,
    imgBiscato,
    imgBiscato
  ],
  tags: ["Web"]
},
{
  id: 7,
  title: "Meu Portfólio",
  descricao: "Portfólio pessoal responsivo desenvolvido para apresentar meus projetos e habilidades como desenvolvedor. Inclui seções sobre minha experiência, habilidades técnicas, projetos destacados e formas de contato, com design limpo e moderno.",
  img: portufolio,
  imgCapa: portufolio,
  linkProjeto: "https://danielkitanaxifilipe.github.io",
  linkGithub: "https://github.com/DanielKitanaxiFilipe/portfolio",
  tecnologias: [
    {name: "HTML5", className: "html-icon"},
    {name: "CSS3", className: "css-icon"},
    {name: "JavaScript", className: "js-icon"},
    {name: "Bootstrap", className: "bootstrap-icon"},
    {name: "Font Awesome", className: "fontawesome-icon"}
  ],
  technologiesUsed: [
    {name: "HTML5"},
    {name: "CSS3"},
    {name: "JavaScript"},
    {name: "Bootstrap 5"},
    {name: "GitHub Pages"}
  ],
  difficultiesFaced: "Criação de layout totalmente responsivo que funcionasse em todos os dispositivos, implementação de animações suaves sem prejudicar performance, e organização do conteúdo para apresentar minha trajetória profissional de forma clara e atraente.",
  solutionsAndLearnings: "Utilizei Bootstrap para garantir responsividade, implementei animações CSS otimizadas e desenvolvi uma estrutura de navegação intuitiva. Aprendi técnicas avançadas de design responsivo e otimização para GitHub Pages.",
  conclusion: "Este portfólio representa minha identidade profissional como desenvolvedor, mostrando tanto minhas habilidades técnicas quanto minha experiência. O projeto evoluiu constantemente junto com minha carreira, servindo como vitrine dinâmica do meu trabalho.",
  imgsPhocts: [
    imgBiscato,
    imgBiscato,
    imgBiscato,
    imgBiscato
  ],
  tags: ["Portfolio", "Responsivo", "Frontend", "Bootstrap"]
},

  {
    id: 8,
    title: "CJD Tech Landing Page",
    descricao: "Landing page profissional para a CJD Tech, empresa angolana de soluções tecnológicas. Apresenta serviços de desenvolvimento de software, hospedagem e consultoria com design moderno e foco em conversão.",
    img: redemin,
    imgCapa: redemin,
    linkProjeto: "https://cjdtech.ao",
    linkGithub: "https://github.com/your-username/cjdtech-landing",
    tecnologias: [
      {name: "React", "className": "react-icon"},
      {name: "Sass", "className": "sass-icon"},
      {name: "React Bootstrap", "className": "bootstrap-icon"},
      {name: "Formik", "className": "formik-icon"},
      {name: "Framer Motion", "className": "framer-icon"}
    ],
    technologiesUsed: [
      {name: "React"},
      {name: "Sass"},
      {name: "React Router"},
      {name: "EmailJS"},
      {name: "React Icons"}
    ],
    difficultiesFaced: "Criação de seções dinâmicas para planos de hospedagem com tabelas comparativas responsivas. Implementação de formulários complexos com validação para agendamento de reuniões. Otimização de performance para carregamento rápido de recursos gráficos.",
    solutionsAndLearnings: "Utilizei React hooks para gerenciamento de estado, Sass modules para estilos escaláveis e Framer Motion para animações performáticas. Implementei Formik para formulários robustos e EmailJS para envio direto de emails. Aprendi técnicas avançadas de design responsivo para tabelas comparativas.",
    conclusion: "A landing page da CJD Tech superou expectativas, combinando design atraente com funcionalidades empresariais complexas. O projeto demonstrou como React e Sass podem criar experiências web profissionais para o mercado angolano de tecnologia.",
    imgsPhocts: [
      redemin,
      redemin,
      redemin
    ],
    tags: ["Landing Page", "Business", "Angola", "Tech Solutions", "Responsive"]
  },
  {
    id: 3,
    title: "Rollaball",
    descricao: "Kandengue Da City é um jogo emocionante de corrida infinita onde os jogadores controlam Mayu, um jovem aventureiro que deve superar diversos obstáculos e desafios em uma cidade vibrante e perigosa. Com uma jogabilidade dinâmica e controles intuitivos, o jogo proporciona uma experiência divertida e viciante para os jogadores.",
    img: rollebal, // Substituir pela importação correta da imagem
    imgCapa: "kandengue-cover.jpg",
    linkProjeto: "https://play.google.com/store/apps/details?id=com.kandenguedacity.game",
    linkGithub: "https://github.com/seu-usuario/KandengueDaCity",
    tecnologias: [
      { name: "Unity", className: "unity-icon" },
      { name: "C#", className: "csharp-icon" },
      { name: "Firebase", className: "firebase-icon" }
    ],
    technologiesUsed: [
      { name: "Unity" },
      { name: "C#" },
      { name: "Firebase" }
    ],
    difficultiesFaced: "Uma das maiores dificuldades enfrentadas no desenvolvimento de Kandengue Da City foi a implementação do Firebase para coletar estatísticas sobre os jogadores. Precisávamos entender quantas pessoas estavam baixando o jogo e quanto tempo elas passavam jogando, mas configurar corretamente essa integração exigiu várias tentativas e testes. Além disso, a movimentação do personagem também foi um grande desafio. Enfrentamos problemas de sincronização das animações com os controles, o que fazia com que os movimentos de Mayu parecessem desajustados ou bugados. A lógica de colisão também apresentou dificuldades, pois algumas colisões não estavam sendo detectadas corretamente, causando falhas na jogabilidade.",
    solutionsAndLearnings: "Para solucionar os desafios técnicos, estudamos a documentação do Firebase e realizamos testes práticos até conseguir capturar os dados dos jogadores corretamente. Implementamos eventos personalizados para rastrear interações específicas, como a duração da sessão e a frequência de jogo. Em relação à movimentação do personagem, ajustamos os tempos das animações e refinamos a lógica de controle, garantindo que os saltos, deslizamentos e desvios ocorressem de forma fluida. Além disso, aplicamos técnicas de depuração para identificar e corrigir as falhas na colisão dos objetos. Esses desafios nos ensinaram a importância de testes rigorosos e ajustes finos na mecânica do jogo.",
    conclusion: "Kandengue Da City se tornou um jogo sólido e divertido, disponível na Google Play Store para Android. Com uma jogabilidade fluida e mecânicas bem ajustadas, os jogadores podem desfrutar de desafios progressivos e uma experiência imersiva. O projeto ainda tem espaço para melhorias e novas funcionalidades, mas os problemas iniciais foram superados com sucesso, garantindo uma experiência agradável para os jogadores.",
    imgsPhocts: [
      imgBiscato,
      imgBiscato,
      imgBiscato,
      imgBiscato
    ],
    tags: ["Game"]
  },


];





export const professional  = [
  {
    "company": "Moveme",
    "position": "Front-end Developer",
    "period": "07/2024 - presente",
    "responsibilities": [
      "Lidero o desenvolvimento e manutenção do dashboard da Moveme, com atualizações de dados em tempo real para motoristas e passageiros.",
      "Implementei melhorias de interface, otimizando a experiência do usuário com funcionalidades de seleção e gestão de corridas.",
      "Gerencio o site da aplicação, garantindo desempenho e confiabilidade, usando React, Bootstrap e SCSS.",
      "Integrei Firebase para sincronização de dados e autenticação.",
      "Participei na criação de um marketplace, conectando motoristas e passageiros para uma experiência mais ágil e pontual."
    ],
    "technologies": ["React", "Bootstrap", "SCSS", "Firebase"]
  },
  {
    "company": "Netlogia",
    "position": "Front-end Developer",
    "period": "03/2023 - presente",
    "responsibilities": [
      "Desenvolvi interfaces modernas e responsivas com Angular, Sass e Bootstrap.",
      "Otimizei a experiência do usuário (UX/UI), aprimorando usabilidade e design visual.",
      "Integrei aplicações front-end com back-end via Firebase, assegurando fluxo de dados em tempo real.",
      "Colaborei com equipes de design e back-end para alinhar projetos aos objetivos de negócio.",
      "Realizei revisões de código e otimizações de desempenho, reduzindo tempos de carregamento e aumentando a eficiência."
    ],
    "technologies": ["Angular", "Sass", "Bootstrap", "Firebase"]
  },
  {
    "company": "Tescan",
    "position": "Front-end Developer",
    "period": "03/2019 - 08/2022",
    "responsibilities": [
      "Desenvolvi e mantive interfaces web responsivas com HTML, CSS e Bootstrap.",
      "Responsável pelo design e UX/UI, criando sites atraentes e acessíveis em diversos dispositivos.",
      "Colaborei com a equipe de back-end para integrar funcionalidades, assegurando uma experiência fluida para o usuário."
    ],
    "technologies": ["HTML", "CSS", "Bootstrap"]
  }
]

