import React from 'react';
import './index.scss';
import { Link } from 'react-router-dom';

interface CardProjectsProps {
  img: string;
  text: string;
  description: string;
  github?: string;
  projectLink?: string;
  projectData: any; // Passar o projeto inteiro via state
  tags?: string[];
}

export const CardProjects = ({
  img,
  text,
  description,
  github,
  projectLink,
  projectData,
  tags = []
}: CardProjectsProps) => {
  return (
    <div className="card-projects">
      {/* Imagem de capa */}
      <div className="capa mb-4">
        <img src={img} alt={text} className="img-fluid" />
      </div>

      {/* Tags do projeto */}
      <div className="mb-2">
        {tags.map((tag, index) => (
          <span key={index} className="badge me-1">{tag}</span>
        ))}
      </div>

      {/* Título e descrição */}
      <div className="mt-3">
        <h6>{text}</h6>
        <p className="opacity-50">{description}</p>
      </div>

      {/* Links do projeto */}
      <div className="mt-3">
        {github && <a href={github} target="_blank" rel="noopener noreferrer" className="me-4">Ver no GitHub</a>}
        {projectLink && <a href={projectLink} target="_blank" rel="noopener noreferrer" className="me-4">Ver Projeto</a>}
        <Link to="/project" state={{ project: projectData }} className="me-4">Saber sobre o projeto</Link>
      </div>
    </div>
  );
};
