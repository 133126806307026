import React, { useEffect, useState } from 'react'
import './style.scss'
import user from './../../asset/img/11.png'
import img from './../../asset/img11.png'
import { HiOutlineDocumentText, HiOutlinePencil, HiOutlinePhotograph, HiOutlinePuzzle, HiOutlineTerminal } from "react-icons/hi";
import Marquee from 'react-fast-marquee';
import { CardProjects } from '../../components/card_projects';
import { projects } from '../../db';
import { useLocation } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomCursor from '../../components/customCursor';

export default function Projeto() {
    const location = useLocation()
    const projeto = location.state?.project;
    
    // Configurações do carrossel de imagens
    const configuracoes = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,  // Desktop: 3 imagens por vez
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024, // Tablets grandes
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768, // Tablets pequenos e celulares grandes
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    
    // Caso o projeto não seja encontrado
    if (!projeto) return <h1>Projeto não encontrado</h1>;

    return (
        <main>
            {/* Barra de navegação */}
            <nav className="navbar pt-3 pb-3 fixed-top">
                <div className="container">
                    <a className="nav-link active">danielkitanaxifilipe12@gmail.com</a>
                    <nav className="nav">
                        <a className="nav-link active">Trabalhos</a>
                        <a className="nav-link">Contato</a>
                    </nav>
                </div>
            </nav>

            {/* Imagem de capa do projeto */}
            <div className="capaProjects" style={{ backgroundImage: `url(${projeto.imgCapa})` }} />

            {/* Seção de descrição do projeto */}
            <section>
                <div className="container pt-5 pb-5">
                    <div className="row justify-content-between">
                        <div className="col-lg-4 mb-4">
                            <h1 className='face-kontora'>
                                {projeto.title}
                            </h1>
                        </div>
                        <div className="col-lg-4 mb-4">
                        </div>
                    </div>
                    <p>
                        {projeto.descricao}
                    </p>
                </div>
            </section>

            {/* Tecnologias utilizadas */}
            <section className='pt-5 pb-5'>
                <div className="container">
                    <h2 className='face-kontora mb-4'>
                        Tecnologias Utilizadas
                    </h2>
                    {projeto.technologiesUsed.map((tech: any) => (
                        <li key={tech.name}>{tech.name}</li>
                    ))}
                </div>
            </section>

            {/* Carrossel de imagens */}
            <Slider {...configuracoes}>
                {projeto.imgsPhocts.map((img: any, index: any) => (
                    <div key={index}>
                        <img src={img} alt={`Imagem ${index + 1}`} className='pe-1 img-fluid' />
                    </div>
                ))}
            </Slider>

            {/* Dificuldades enfrentadas */}
            <section className='mt-5 pt-5 pb-5'>
                <div className="container">
                    <h2 className='face-kontora mb-4'>
                        Dificuldades Enfrentadas
                    </h2>
                    {projeto.difficultiesFaced}
                </div>
            </section>

            {/* Soluções e aprendizados */}
            <section className='mt-5 pt-5 pb-5'>
                <div className="container">
                    <h2 className='face-kontora mb-4'>
                        Soluções e Aprendizados
                    </h2>
                    {projeto.solutionsAndLearnings}
                </div>
            </section>

            {/* Conclusão */}
            <section className='mt-5 pt-5 pb-5'>
                <div className="container">
                    <h2 className='face-kontora mb-4'>
                        Conclusão
                    </h2>
                    {projeto.conclusion}
                </div>
            </section>

            {/* Mensagem inspiradora */}
            <section className='spc d-flex align-items-center'>
                <div className="container text-center">
                    <h2 className='face-kontora'>
                        Cada linha de código é uma oportunidade para melhorar a vida de alguém
                    </h2>
                </div>
            </section>

            {/* Rodapé */}
            <footer className='d-flex align-items-center'>
                <div className="container">
                    <nav className="nav justify-content-center">
                        <a className="nav-link active">danielkitanaxifilipe12@gmail.com</a>
                        <a className="nav-link active">|</a>
                        <a className="nav-link active">LinkedIn</a>
                        <a className="nav-link active">GitHub</a>
                        <a className="nav-link">Portfólio</a>
                        <a className="nav-link">WhatsApp</a>
                    </nav>
                </div>
            </footer>
        </main>
    );
}
